<template>
    <measure-pane></measure-pane>
</template>

<script>
Array.prototype.setAllVisiblity = function(v) {
    var i,
        n = this.length;
    for (i = 0; i < n; ++i) {
        this[i].visible = v;
    }
};

/**
 * Add any custom component as a leaflet control
 */
import MeasurePane from '../../EmergencyManagement/MeasurePane.vue';

import { spatialService } from '../../../services/spatial.services.js';

export default {
    name: 'MeasureSidebar',
    components: {
        MeasurePane,
    },
    mixins: [],
    props: {},
    data() {
        return {};
    },
    created() {},
    mounted() {},
    render() {
        return null;
    },
    methods: {},
    computed: {},
    watch: {},
};
</script>

<style>
.buttonContainer {
    padding: 20px;
    background-color: white;
}
.radioButton {
    display: inline-block;
}
.select-frame {
    text-align: center;
    padding-top: 1rem;
}
#radioRow {
    padding-bottom: 7px;
    display: flex;
    justify-content: space-around;
}
.unitSelect {
    width: 260px;
    height: 36px;
    border-radius: 4px;
    align-items: center;
}
.distanceEntry {
    width: 60px;
}
.label {
    margin-left: 5px;
}
.btn-gray {
    background-color: gray !important;
    color: white;
    height: 42px;
    width: 100px;
}
.btn-blue {
    background-color: rgb(77, 194, 233) !important;
}
.btn-clear {
    border: 1px solid gray;
}
.descText {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
</style>
