<template>
    <div ref="measurePane" class="buttonContainer">
        <div class="descText">
            Please use the following buttons to pick a measurement type and
            unit. Then click measure to begin measuring on the map.
        </div>
        <!-- row for the radiobuttons to change between tabs -->
        <div id="radioRow">
            <span>
                <input
                    class="radioButton"
                    id="areaRadio"
                    type="radio"
                    value="Area"
                    v-model="measureType"
                    @click="measurement = ''"
                />
                <label class="label" for="areaRadio">Area</label>
            </span>
            <span>
                <input
                    class="radioButton"
                    id="distanceRadio"
                    type="radio"
                    value="Distance"
                    v-model="measureType"
                    @click="measurement = ''"
                />
                <label class="label" for="distanceRadio">Distance</label>
            </span>
            <span>
                <input
                    class="radioButton"
                    id="locationRadio"
                    type="radio"
                    value="Location"
                    v-model="measureType"
                    @click="measurement = ''"
                />
                <label class="label" for="pointRadio">Coordinate</label>
            </span>
        </div>
        <select
            ref="areaUnitSelect"
            v-show="measureType == 'Area'"
            class="unitSelect"
            @change="setMeasurementUnits"
        >
            <option
                v-for="(unit, index) in units['area']"
                :key="index"
                :value="unit"
            >
                {{ unit }}
            </option>
        </select>
        <select
            ref="distanceUnitSelect"
            v-show="measureType == 'Distance'"
            class="unitSelect"
            @change="setMeasurementUnits"
        >
            <option
                v-for="(unit, index) in units['distance']"
                :key="index"
                :value="unit"
            >
                {{ unit }}
            </option>
        </select>
        <select
            ref="locationUnitSelect"
            v-show="measureType == 'Location'"
            class="unitSelect"
            @change="setMeasurementUnits"
        >
            <option
                v-for="(unit, index) in units['location']"
                :key="index"
                :value="unit"
            >
                {{ unit }}
            </option> </select
        ><br />
        <!-- Measure Area tab -->
        {{ convertedMeasurement }}
        <br />
        <div class="d-flex justify-content-between pb-1 bottom-btn-container">
            <button
                class="buttonSelect"
                v-bind:class="
                    activeMapTool.startsWith('measure')
                        ? 'btn-blue'
                        : 'buttonSelect'
                "
                @click="startMeasure"
            >
                Measure
            </button>
            <span
                v-hide="activeMapTool == ''"
                class="clear-text"
                @click="cancelMeasure"
            >
                Cancel
            </span>
            <span
                v-show="measureFeatureCount"
                class="clear-text"
                @click="clearMeasure"
            >
                Clear
            </span>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { getPathLength, getAreaOfPolygon } from 'geolib';
import { drawingMixin } from '../../mixins/drawing.mixin';
import hide from '../../directives/hide';
/**
 * Add any custom component as a leaflet control
 */
export default {
    name: 'MeasurePane',
    components: {},
    mixins: [drawingMixin],
    props: {},
    data() {
        return {
            measureType: 'Area',
            measureShapeColor: 'orange',
            startPointRadius: 2,
            measurement: '',
            measureUnits: 'acres',
            width: 300,
            units: {
                location: ['Decimal Degrees', 'D° M\' S"'],
                distance: ['Meters', 'Feet', 'Kilometers', 'Miles'],
                area: [
                    'Acres',
                    'Hectares',
                    'Square Meters',
                    'Square Feet',
                    'Square Kilometers',
                    'Square Miles',
                ],
            },
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateDrawPanePosition);
        this.$on('map/click', this.handleMouseClick);
        this.$on('map/dblClick', this.handleMouseDblClick);
        this.$on('ready', this.measureReady);
        this.$emit('ready', this.mapObject);
    },
    render() {
        return null;
    },
    methods: {
        convertLocation(coordinates, target_unit) {
            const conversions = {
                'Decimal Degrees':
                    coordinates.lat.toFixed(6) +
                    ', ' +
                    coordinates.lng.toFixed(6),

                'D° M\' S"': this.dd2dms(coordinates.lat, coordinates.lng),
            };
            return conversions[target_unit];
        },
        convertDistance(distance_meters, target_unit) {
            const conversions = {
                Meters: 1,
                Feet: 3.28084,
                Kilometers: 0.001,
                Miles: 0.000621371,
            };
            var convertedDistance = distance_meters * conversions[target_unit];
            return convertedDistance.toFixed(6);
        },
        convertArea(area_meters, target_unit) {
            const conversions = {
                Acres: 0.000247105,
                Hectares: 0.0001,
                'Square Meters': 1,
                'Square Feet': 10.7639,
                'Square Kilometers': 0.000001,
                'Square Miles': 0.000000386102,
            };
            var convertedArea = area_meters * conversions[target_unit];
            return convertedArea.toFixed(6);
        },
        startMeasure() {
            this.clearBufferShapes();
            this.measurement = '';
            if (this.measureType == 'Location') {
                this.measureUnits = this.$refs.locationUnitSelect.value;
                this.measureLocation();
            } else if (this.measureType == 'Distance') {
                this.measureUnits = this.$refs.distanceUnitSelect.value;
                this.measureDistance();
            } else if (this.measureType == 'Area') {
                this.measureUnits = this.$refs.areaUnitSelect.value;
                this.measureArea();
            }
        },
        cancelMeasure() {
            this.clearMapTool();
        },
        clearMeasure() {
            this.measurement = '';
            let drawMarkers = this.drawMarkers.filter(
                (feature) => !feature.id.startsWith('MC')
            );
            let drawLines = this.drawLines.filter(
                (feature) => !feature.id.startsWith('ML')
            );
            let drawPolygons = this.drawPolygons.filter(
                (feature) => !feature.id.startsWith('MP')
            );
            this.$store.commit('oneMap/mutateDrawMarkers', drawMarkers);
            this.$store.commit('oneMap/mutateDrawLines', drawLines);
            this.$store.commit('oneMap/mutateDrawPolygons', drawPolygons);
            this.clearMapTool();
        },
        setMeasurementUnits(evt) {
            this.measureUnits = evt.target.value;
        },
        clearBufferShapes() {
            var i = this.drawMarkers.length;
            while (i--) {
                if (this.drawMarkers.at(i).id.startsWith('M')) {
                    this.drawMarkers.splice(i, 1);
                }
            }
            i = this.drawLines.length;
            while (i--) {
                if (this.drawLines.at(i).id.startsWith('M')) {
                    this.drawLines.splice(i, 1);
                }
            }
            i = this.drawPolygons.length;
            while (i--) {
                if (this.drawPolygons.at(i).id.startsWith('M')) {
                    this.drawPolygons.splice(i, 1);
                }
            }
        },
        cleanupShapes() {
            var i = this.drawLines.length;
            while (i--) {
                if (this.drawLines.at(i).geometry.coordinates.length < 2) {
                    this.clearMapTool();
                }
            }
            i = this.drawPolygons.length;
            while (i--) {
                if (
                    this.drawPolygons.at(i).geometry.coordinates[0].length < 3
                ) {
                    this.drawPolygons.splice(i, 1);
                }
            }
        }, // changes an array of arrays to an array of objects
        convertCoordinateStruct(coordinates) {
            let convertedCoordinates = [];
            coordinates.forEach((coordinate) => {
                convertedCoordinates.push({
                    latitude: coordinate[0],
                    longitude: coordinate[1],
                });
            });
            return convertedCoordinates;
        },

        getMarkerFeature(lat, lng) {
            return {
                // using letters and the count to create unique values between different object arrays
                //    since point 1 and line 1 would be overlapping and throw lots of warnings in the console
                //    but not errors that will cause issues in the actual execution/functionality
                // for this tool we use MC, ML, MP for Measure-Circle, Measure-Line, and Polygon
                //    this separates it from the drawtools C, L, P for Circle, Line Polygon
                id: 'MC',
                geometry: {
                    type: 'Point',
                    coordinates: [lat, lng],
                    coordSys: 'LatLong',
                },
                properties: {},
                styling: {
                    SymbologyColour: this.measureShapeColor,
                    SymbologySize: 3,
                    PolygonColor: this.measureShapeColor,
                    PolygonTransparency: 0,
                },
            };
        },
        pointClickHandler(markerFeature) {
            this.clearMapTool();
            this.$store.commit('oneMap/addDrawMarker', markerFeature);
            let [lat, lng] = markerFeature.geometry.coordinates;
            this.measurement = { lat: lat, lng: lng };
        },
        lineClickHandler(markerFeature) {
            if (this.drawLines.at(-1).geometry.coordinates.length == 0) {
                this.drawMarkers.push(markerFeature);
            } else if (this.drawLines.at(-1).geometry.coordinates.length == 1) {
                this.removeLastDrawMarker();
            }
            this.$store.commit(
                'oneMap/pushLineVertex',
                markerFeature.geometry.coordinates
            );
            this.measurement = getPathLength(
                this.convertCoordinateStruct(
                    this.drawLines.at(-1).geometry.coordinates
                )
            );
        },
        polygonClickHandler(markerFeature) {
            if (this.drawPolygons.at(-1).geometry.coordinates[0].length == 0) {
                this.$store.commit('oneMap/addDrawMarker', markerFeature);
            } else if (this.drawLines.at(-1).geometry.coordinates.length == 1) {
                this.removeLastDrawMarker();
            }
            this.$store.commit(
                'oneMap/pushLineVertex',
                markerFeature.geometry.coordinates
            );
            this.$store.commit(
                'oneMap/pushPolygonVertex',
                markerFeature.geometry.coordinates
            );
            this.measurement = getAreaOfPolygon(
                this.convertCoordinateStruct(
                    this.drawPolygons.at(-1).geometry.coordinates[0]
                )
            ).toFixed(2);
        },
        measureLocation() {
            this.cleanupShapes('point');
            if (this.activeMapTool == 'measureLocation') {
                this.cancelMeasure();
            } else {
                this.setMapTool('measureLocation');
                this.measurement = '';
            }
        },
        measureDistance() {
            this.cleanupShapes('line');
            if (this.activeMapTool == 'measureDistance') {
                this.cancelMeasure();
            } else {
                this.setMapTool('measureDistance');
                this.drawLines.push({
                    id: 'ML' + this.drawLines.length,
                    geometry: {
                        coordinates: [],
                        type: 'LineString',
                        coordSys: 'LatLong',
                    },
                    properties: {
                        length: 0,
                    },
                    styling: {
                        PolygonBorderColour: this.measureShapeColor,
                    },
                });
                this.measurement = '';
            }
        },
        measureArea() {
            this.cleanupShapes('polygon');
            if (this.activeMapTool == 'measureArea') {
                this.cancelMeasure();
            } else {
                this.setMapTool('measureArea');
                this.drawLines.push({
                    id: 'ML' + this.drawLines.length,
                    geometry: {
                        coordinates: [],
                        type: 'LineString',
                        coordSys: 'LatLong',
                    },
                    properties: {
                        length: 0,
                    },
                    styling: {
                        PolygonBorderColour: this.measureShapeColor,
                    },
                });
                this.drawPolygons.push({
                    id: 'MP' + this.drawPolygons.length,
                    geometry: {
                        coordinates: [[]],
                        type: 'Polygon',
                        coordSys: 'LatLong',
                    },
                    properties: {
                        length: 0,
                    },
                    styling: {
                        PolygonColor: this.measureShapeColor,
                        PolygonBorderColour: this.measureShapeColor,
                        PolygonTransparency: 0.8,
                    },
                });
                this.measurement = '';
            }
        },
        toDMS(coordinate) {
            var absolute = Math.abs(coordinate);
            var degrees = Math.floor(absolute);
            var minutesNotTruncated = (absolute - degrees) * 60;
            var minutes = Math.floor(minutesNotTruncated);
            var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

            return degrees + '° ' + minutes + "' " + seconds + '"';
        },
        dd2dms(lat, lng) {
            var newCoord = '';
            if (lat > 0) {
                newCoord += this.toDMS(lat) + 'N ';
            } else {
                newCoord += this.toDMS(Math.abs(lat)) + 'S ';
            }
            if (lng > 0) {
                newCoord += this.toDMS(lng) + 'E ';
            } else {
                newCoord += this.toDMS(Math.abs(lng)) + 'W ';
            }
            return newCoord;
        },
        measureReady() {},
    },
    computed: {
        ...mapGetters('oneMap', ['measureFeatureCount']),

        convertedMeasurement: function() {
            if (this.measurement == '' || parseFloat(this.measurement) == 0) {
                return '';
            } else if (this.measureType == 'Location') {
                return this.convertLocation(
                    this.measurement,
                    this.measureUnits
                );
            } else if (this.measureType == 'Distance') {
                return this.convertDistance(
                    parseFloat(this.measurement),
                    this.measureUnits
                );
            } else if (this.measureType == 'Area') {
                return this.convertArea(
                    parseFloat(this.measurement),
                    this.measureUnits
                );
            }
            return '';
        },
    },
    watch: {},
};
</script>

<style scoped>
.buttonContainer {
    padding: 20px;
    background-color: white;
}
.radioButton {
    display: inline-block;
}
#radioRow {
    padding-bottom: 7px;
    display: flex;
    justify-content: space-around;
}
.unitSelect {
    width: 260px;
    height: 36px;
    border-radius: 4px;
}
.distanceEntry {
    width: 60px;
}
.label {
    margin-left: 5px;
}
.btn-gray {
    background-color: gray !important;
    color: white;
    height: 42px;
    /* width: 100px; */
}
.btn-blue {
    background-color: #00496d !important;
}
.btn-clear {
    border: 1px solid gray;
}
.descText {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
.clear-text {
    /* position: relative; */
    float: left;
    width: 112px;
    height: 20px;
    margin-left: 20px;
    margin-top: 14px;
    font: normal normal 600 16px/24px IBM Plex Sans;
    letter-spacing: 0.14px;
    color: #006395;
    cursor: pointer;
}
.bottom-btn-container {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    height: 100px;
    margin: auto;
    /* background: #f4f4f4 0% 0% no-repeat padding-box; */
}
</style>
